import React from "react";
import { FiInstagram, FiFacebook, FiTwitter, FiYoutube } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./Footer.css";
import { Logo } from "../assets";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section footer-logo">
        <img src={Logo} alt="Black & Fighter Logo" />
      </div>

      <div className="footer-section footer-nav">
        <div className="navigation">
          <Link to="/">Home</Link>
          <Link to="/products-page?category=home-appliances">Products</Link>
          <Link to="/about-page/">About</Link>
          <Link to="/contact-page/">Contact</Link>
        </div>
        <p>© 2024 Black & Fighter. All rights reserved.</p>
      </div>

      <div className="footer-section footer-social-icons">
        <div className="socials">
          <a
            href="https://www.instagram.com/blackandfighter/"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiInstagram />
          </a>

          <a
            href="https://www.facebook.com/profile.php?id=61565362530350"
            aria-label="Facebook"
          >
            <FiFacebook />
          </a>
          <a href="https://x.com/blackandfighter" aria-label="Twitter">
            <FiTwitter />
          </a>
          <a
            href="https://www.youtube.com/channel/UC_3RKyzgHhjM4slFerik0bA"
            aria-label="YouTube"
          >
            <FiYoutube />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
