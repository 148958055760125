import React from "react";
import { Click, Cooker, Featuredproduct, Power, Thunder, Warm } from "../assets";
import "../Compontents/ProductFeatures.css"
const ProductFeatures = () => {
  return (
    <div className="product-feature-main">
      <div className="product-features">
      <div className="first-part">
        <div className="top-part">
          <div className="features yellow">
            <img src={Thunder} alt="Voltage" />
            <p>220-240V</p>
          </div>
          <div className="features white-bg">
            <img src={Cooker} alt="Removable Non Stick" />
            <p>Removable Non Stick</p>
          </div>
        </div>
        <div className="bottom-part yellow warranty">
          <div className="features">
            <p>2 YEARS</p>
            <span>WARRANTY</span>
          </div>
        </div>
      </div>
      <div className="second-part">
        <div className="features main-product">
          <img src={Featuredproduct} alt="" />
        </div>
      </div>
      <div className="third-part">
        <div className="top-part">
          <div className="features yellow">
            <img src={Warm} alt="Keep Warm Functions" />
            <p>Keep Warm Functions</p>
          </div>
          <div className="features white-bg">
            <img src={Power} alt="Product" />
            <p>700 W</p>
          </div>
        </div>
        <div className="bottom-part yellow">
          <div className="features">
            <img src={Click} alt="Cool Touch" />
            <p>Cool Touch</p>
          </div>
        </div>
      </div>
    </div>
      <div className="product-descriptions">
        <h2>SmartSense Technology for healthier life</h2>
        <p>Perfect for Every Meal!</p>
      </div>
    </div>
  );
};

export default ProductFeatures;
