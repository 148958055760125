import React, { useState, useEffect } from 'react';
import "../Compontents/Featured.css"
import { BF8001, BF3063 } from '../assets'; // Add more product images
import { Link } from 'react-router-dom';

const Featured = () => {
  const products = [
    {
      id: 1,
      name: 'Coffee Maker',
      description: 'Brew your perfect cup in seconds with ease and style!',
      image: BF8001,
    },
    {
      id: 2,
      name: 'Hair Dryer',
      description: 'Achieve sleek, salon-quality hair in no time with effortless power!',
      image: BF3063,
    },
  ];

  const [currentProductIndex, setCurrentProductIndex] = useState(0);

  // Change product every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProductIndex((prevIndex) => (prevIndex + 1) % products.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup
  }, []);

  const currentProduct = products[currentProductIndex];

  return (
    <div className="featured">
      <div className="heading">FEATURED</div>
      <hr />
      <div className="product-section">
        <div className="product-details">
          <span className="sale-badge">UPCOMING PRODUCTS</span>
          <h2 className="fade">{currentProduct.name}</h2>
          <p className="fade">{currentProduct.description}</p>
          <Link to="/products-page" className="view-button2" aria-label="Explore more products">
            Explore More
          </Link>
        </div>
        <div className="product-image-container">
          {/** Ensure the purpose of "cutting" is clear */}
          <div className="cutting"></div>
          <img src={currentProduct.image} alt={`Image of ${currentProduct.name}`} className="product-image fade"/>
          <div className="thumbnail">
            <img
              src={currentProduct.image} // Dynamic thumbnail
              alt={`Thumbnail of ${currentProduct.name}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured