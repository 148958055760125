import React, { useState } from 'react';

const Sidebar = ({ filterProducts, productCount }) => {
  const [activeFilter, setActiveFilter] = useState({ type: "", value: "" });
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const tags = ["All", "Best Seller", "Sales", "New"];
  const products = [
    "Air Fryer", "Blender", "Electric Kettle", "Gas Stove",
    "Electric Rice Cooker", "LED Flashlight",
    ["Steam Iron", "Heavy Dry Iron"],
    "Electric Food Chopper", "Hair Straightener",
    "Hair Trimmer", "Sandwich Maker"
  ];
  const sizes = ["Small", "Medium", "Large"];

  const handleFilter = (type, value) => {
    filterProducts(type, value);
    setActiveFilter({ type, value });
  };

  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="sidebar-section">
          <h3>Products - ({productCount})</h3>
        </div>
        <div className="sidebar-section">
          <h3>SHOP ALL</h3>
          <ul>
            {tags.map((tag) => (
              <li
                key={tag}
                role="button"
                tabIndex={0}
                className={activeFilter.type === "tag" && activeFilter.value === tag ? "active" : ""}
                onClick={() => handleFilter("tag", tag)}
                onKeyPress={(e) => e.key === "Enter" && handleFilter("tag", tag)}
              >
                {tag}
              </li>
            ))}
          </ul>
        </div>
        <div className="sidebar-section">
          <h3>PRODUCTS</h3>
          <ul>
            {products.map((product, index) => (
              <li
                key={index}
                role="button"
                tabIndex={0}
                onClick={() => handleFilter("name", product)}
                onKeyPress={(e) => e.key === "Enter" && handleFilter("name", product)}
              >
                {Array.isArray(product) ? product.join(" / ") : product}
              </li>
            ))}
          </ul>
        </div>
        <div className="sidebar-section">
          <h3>Size</h3>
          <ul>
            {sizes.map((size) => (
              <li
                key={size}
                role="button"
                tabIndex={0}
                onClick={() => handleFilter("size", size)}
                onKeyPress={(e) => e.key === "Enter" && handleFilter("size", size)}
              >
                {size}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
