import React from "react";
import "../Compontents/AboutSection.css";
const AboutSection = () => {
  return (
    <div className="abt-section">
      <div className="heading">ABOUT THE BRAND</div>
      <hr />
      <p className="abt-content">
        BLACK&FIGHTER® Electronics has been a leader in the electronics and home
        appliances industry for over 20 years, with a strong presence in Saudi
        Arabia and Qatar. We are committed to quality, innovation, and
        reliability, offering high-performance products designed to enhance
        everyday life. Our skilled team ensures excellence from design to
        customer service, with each product tested for consistent performance
        and reliability. Our mission is to empower families worldwide with
        products that make life more convenient, enjoyable, and sustainable.
        Guided by our values of Innovation, Teamwork, and Reliability, we strive
        to exceed customer expectations and build trust in everything we do.
      </p>
    </div>
  );
};

export default AboutSection;
