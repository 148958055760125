import React from 'react'
import Navbar from '../Compontents/Navbar'
import Productinfo from '../Compontents/Productinfo'
import Footer from '../Compontents/Footer'

const Productinfopage = () => {
  return (
    <>
    <Navbar/>
    <Productinfo/>
    <Footer/>
    </>
  )
}

export default Productinfopage