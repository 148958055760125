import React, { useState } from "react";
import "../Compontents/ContactSection.css";
import emailjs from "emailjs-com";
import { BsArrowRightCircleFill } from "react-icons/bs";

const ContactSection = () => {
  const [email, setEmail] = useState(""); // State to track user input
  const [status, setStatus] = useState(""); // State to display feedback

  const sendEmail = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setStatus("Please enter a valid email address.");
      return;
    }

    const templateParams = {
      to_email: email, // Dynamic email from input
    };

    emailjs
      .send(
        "service_gcow8fl", // Replace with your EmailJS Service ID
        "template_u2c6wfe", // Replace with your EmailJS Template ID
        templateParams,
        "ELUbaXaN4hVI6KuEh" // Replace with your EmailJS User ID
      )
      .then(
        (response) => {
          setStatus("Subscription successful! Check your inbox.");
          setEmail(""); // Clear the input field
        },
        (error) => {
          setStatus("Failed to send email. Please try again later.");
          console.error("EmailJS error:", error);
        }
      );
  };

  return (
    <div className="contact-section">
      <div className="subscribe">
        <h3>Subscribe</h3>
        <p>Stay updated with Black and Fighter</p>
        <form onSubmit={sendEmail} className="subscribe-input">
          <input
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Track input value
            required
          />
          <button type="submit">
            <BsArrowRightCircleFill />
          </button>
        </form>
        {status && <p className="status-message">{status}</p>} {/* Display feedback */}
      </div>
      <div className="help">
        <h3>Need help?</h3>
        <p>Discounts and news about products</p>
        <a href="https://wa.me/+97474717616" className="contact-btn">Contact Us</a>
      </div>
    </div>
  );
};

export default ContactSection;