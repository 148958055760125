import React from "react";
import { Link, useParams } from "react-router-dom";
import "../Compontents/Productinfo.css";
import {
  BF7001,
  BF7002,
  BF5001,
  BF5002,
  BF5003,
  BF5004,
  BF5005,
  BF5006,
  BF7003,
  BF7007,
  BF5501,
  BF5502,
  BF5503,
  BF6001,
  BF6002,
  BF6003,
  BF6006,
  BF4001,
  BF4002,
  BF4003,
  BF4004,
  BF2002GS,
  BF2006GS,
  BF3001,
  BF3002,
  BF3003,
  BF3004,
  BF3005,
  BF3006,
  BF3007,
  BF3008,
  BF3009,
  BF3050,
  BF1004,
  BF1006,
  BF1007,
  BF1008,
  BF1004V2,
  BF1005V3,
  BF1005V4,
  BF1001,
  BF1002,
  BF1003,
  BF9007,
  BF4501,
  BF4502,
  BF4503,
} from "../assets";

const Productinfo = () => {
  const { productId } = useParams();

  const products = [
    {
      id: 1,
      name: "Steam Iron",
      model: "BF 7001",
      image: BF7001,
      tags: ["Best Seller"],
      size: "Medium",
      description: "Features",
      list: [
        "Nano Ceramic Soleplate - 2200W",
        "Self-Cleaning",
        "Micro Spray",
        "Anti Calc & Anti Drip",
        "Over Heat Protection",
        "Powerful Burst Steam",
        "Variable Steam Control",
      ],
    },
    {
      id: 2,
      name: "Steam Iron",
      model: "BF 7002",
      image: BF7002,
      tags: ["New"],
      size: "Small",
      list: [
        "Variable Steam Control",
        "Anti-calc/Anti-drip/Auto shut-off Function",
        "Black Nano Ceramic Soleplate",
        "Overheat Safety Protection",
        "Dry Ironing and Spray Function",
      ],
    },
    {
      id: 3,
      name: "Steam Iron",
      model: "BF 7003",
      image: BF7003,
      tags: ["New"],
      size: "Small",
      list: [
        "Overheat Safety Production",
        "Grey Ceramic Coating Soleplate",
        "2000 W",
        "Self-Cleaning",
        "Micro Spray",
        "Over Heat Protection",
        "Anti Calc & Anti Drip",
        "Powerful Burst Steam",
      ],
    },
    {
      id: 4,
      name: "Heavy Dry Iron",
      model: "BF 7007",
      image: BF7007,
      tags: ["Sales"],
      size: "Large",
      list: [
        "Easy Gliding Non-Stick Golden Soleplate",
        "Indicator Light",
        "1200W, Swivel Cord",
        "Shock Proof Handle",
        "Safety Fuse",
        "6 Temperature Setting",
        "Auto Off Function",
      ],
    },
    {
      id: 5,
      name: "2 in 1 Blender with Grinder & Crusher",
      model: "BF 5001",
      image: BF5001,
      tags: [""],
      size: "",
      list: [
        "Auto-off safety",
        "Ice Cube Crush Function",
        "Overheat & Over Current",
        "Double Protection",
        "1.5L high-capacity jar",
        "Stainless Steel Housing Base",
        "650W",
      ],
    },
    {
      id: 6,
      name: "2 in 1 Blender with Grinder & Crusher",
      model: "BF 5002",
      image: BF5002,
      tags: [""],
      size: "",
      list: [
        "Auto-off safety",
        "Ice Cube Crush Function",
        "Overheat & Over Current Double Protection",
        "1.5L high-capacity jar",
        "Stainless steel housing base",
        "650W",
      ],
    },
    {
      id: 7,
      name: "2 in 1 Blender with Grinder & Crusher",
      model: "BF 5003",
      image: BF5003,
      tags: [""],
      size: "",
      list: [
        "1.5 L High Capacity Jar",
        "Point Stainless Steel",
        "Anti-Slip Base & Airtight Lid",
        "450W",
        "Overheat & Over Current Double Protection",
        "Unbreakable Jar",
        "Ice Cube Crush Function",
      ],
    },
    {
      id: 8,
      name: "2 in 1 Blender with Grinder",
      model: "BF 5004",
      image: BF5004,
      tags: [""],
      size: "",
      list: [
        "1.6 L High Capacity Jar",
        "4-Point Stainless Steel Blades",
        "Anti-Slip Base & Airtight Lid",
        "450W",
        "Overheat & Over Current Double Protection",
        "Unbreakable Jar",
      ],
    },
    {
      id: 9,
      name: "3 in 1 Blender with Grinder & Crusher",
      model: "BF 5005",
      image: BF5005,
      tags: [""],
      size: "",
      list: [
        "1.5 L High Capacity Jar",
        "6-Point Stainless Steel Blades",
        "Anti-Slip Base & Airtight Lid",
        "Ice Cube Crush Function",
        "700W",
        "Overheat & Over Current Double Protection",
        "Auto-Off Safety",
        "Unbreakable Jar",
      ],
    },
    {
      id: 10,
      name: "2 in 1 Blender with Grinder",
      model: "BF 5006",
      image: BF5006,
      tags: [""],
      size: "",
      list: [
        "1.6 L High Capacity Jar",
        "4-Point Stainless Steel Blades",
        "Anti-Slip Base & Airtight Lid",
        "350W",
        "Overheat & Over Current Double Protection",
        "Auto-Off Safety",
        "Unbreakable Jar",
      ],
    },
    {
      id: 11,
      name: "Electric Food Chopper",
      model: "BF 5501",
      image: BF5501,
      tags: [""],
      size: "",
      list: [
        "400W, Capacity 2L",
        "Thermal Overload Protection",
        "Secure Lid Lock",
        "Electric Shock Protection",
        "4 Leaf Sharp Blade With Safety Guard",
        "Anti-Slip Mat",
        "5 Speed Button",
      ],
    },
    {
      id: 12,
      name: "Electric Food Chopper",
      model: "BF 5502",
      image: BF5502,
      tags: [""],
      size: "",
      list: [
        "400W, Capacity 2L",
        "Thermal Overload Protection",
        "Secure Lid Lock",
        "Electric Shock Protection",
        "4 Leaf Sharp Blade With Safety Guard",
        "Anti-Slip Mat",
        "3 Speed Button",
      ],
    },
    {
      id: 13,
      name: "Electric Food Chopper",
      model: "BF 5503",
      image: BF5503,
      tags: [""],
      size: "",
      list: [
        "450W, Capacity 2L",
        "Thermal Overload Protection",
        "Secure Lid Lock",
        "Electric Shock Protection",
        "6 Leaf Sharp Blade With Safety Guard",
        "Anti-Slip Mat",
        "3 Speed Button",
      ],
    },
    {
      id: 14,
      name: "Electric Kettle",
      model: "BF 6001",
      image: BF6001,
      tags: [""],
      size: "",
      list: [
        "Overheat protection",
        "360-degree Rotational Base",
        "Shut Off Automatically",
        "LED Boiling Indicator",
        "Double Walled Stainless Steel & Polypropylene Body",
        "1500W & Capacity 1.8L",
      ],
    },
    {
      id: 15,
      name: "Electric Kettle",
      model: "BF 6002",
      image: BF6002,
      tags: [""],
      size: "",
      list: [
        "Overheat protection",
        "360-degree Rotational Base",
        "Shut Off Automatically",
        "LED Boiling Indicator",
        "Solicitous Stainless Steel Body",
        "Double Heating Controller",
        "1500W & Capacity 1.8L",
      ],
    },
    {
      id: 16,
      name: "Electric Kettle",
      model: "BF 6003",
      image: BF6003,
      tags: [""],
      size: "",
      list: [
        "Overheat protection",
        "360-degree Rotational Base",
        "Shut Off Automatically",
        "LED Boiling Indicator",
        "Solicitous Stainless Steel Body",
        "Double Heating Controller",
        "1500W & Capacity 1.8L",
      ],
    },
    {
      id: 17,
      name: "Electric Kettle",
      model: "BF 6006",
      image: BF6006,
      tags: [""],
      size: "",
      list: [
        "Overheat protection",
        "360-degree Rotational Base",
        "1500W & Capacity 1.8L",
        "Neon indicator light",
        "Automatic keep WARM function",
        "Dry Boil Protection",
        "Off Automatically",
      ],
    },
    {
      id: 18,
      name: "Electric Rice Cooker",
      model: "BF 4001",
      image: BF4001,
      tags: [""],
      size: "",
      list: [
        "Cool Touch Handles",
        "0.6L & 300W",
        "Thermostat & Micro Switch Function",
        "Full Copper Code",
        "Oil Non-Stick Coating",
        "Inner Pot",
        "Dry Burning Prevention",
      ],
    },
    {
      id: 19,
      name: "Electric Rice Cooker",
      model: "BF 4002",
      image: BF4002,
      tags: [""],
      size: "",
      list: [
        "Removable Non-stick Coating Inner Pot",
        "Cool Touch Handles",
        "Dry Burning Prevention Function",
        "Rated Power: 400W & 1.0L",
        "Thermostat Function",
        "Micro Switch System",
        "Detachable Full Copper Cord",
        "Removable Steamer",
      ],
    },
    {
      id: 20,
      name: "Electric Rice Cooker",
      model: "BF 4003",
      image: BF4003,
      tags: [""],
      size: "",
      list: [
        "Removable Non-stick Coating Inner Pot",
        "Cool Touch Handles",
        "Dry Burning Prevention Function",
        "Rated Power: 500W & 1.5L",
        "Thermostat Function",
        "Micro Switch System",
        "Detachable Full Copper Cord",
        "Removable Steamer",
      ],
    },
    {
      id: 21,
      name: "Electric Rice Cooker",
      model: "BF 4004",
      image: BF4004,
      tags: [""],
      size: "",
      list: [
        "Removable Non-stick Coating Inner Pot",
        "Cool Touch Handles",
        "Dry Burning Prevention Function",
        "Rated Power: 700W & 1.8L",
        "Thermostat Function",
        "Micro Switch System",
        "Detachable Full Copper Cord",
        "Removable Steamer",
      ],
    },
    {
      id: 22,
      name: "Gas Stove",
      model: "BF 2002 GS",
      image: BF2002GS,
      tags: [""],
      size: "",
      list: [
        "Double Burner",
        "410 Stainless Steel",
        "Low Gas Consumption",
        "Japan Automatic Piezo Ignition Technology",
        "Electroplated Pan",
        "Support & Easy Clean",
      ],
    },
    {
      id: 23,
      name: "Gas Stove",
      model: "BF 2006 GS",
      image: BF2006GS,
      tags: [""],
      size: "",
      list: [
        "7MM Thickness Glass",
        "Heat Resistant Glass",
        "Low Gas Consumption",
        "Japan Automatic Ignition",
        "Stainless Steel Frame and Tray",
        "Convenient & Easy to Cleaning",
      ],
    },
    {
      id: 24,
      name: "Hair Trimmer",
      model: "BF 3001",
      image: BF3001,
      tags: [""],
      size: "",
      list: [
        "High Precision Sharp Blade",
        "Charging Time 3H",
        "Discharging Time 45 mins",
        "NI-CD Battery",
        "Durable long Lasting Motor",
      ],
    },
    {
      id: 25,
      name: "Hair Trimmer",
      model: "BF 3002",
      image: BF3002,
      tags: [""],
      size: "",
      list: [
        "Rechargeable Professional Hair Trimmer",
        "LED Display",
        "Charging & Discharging Time 2.5H",
        "Li-ion Battery 1200mah",
        "Durable long Lasting Motor",
        "High Precision Sharp Blade",
      ],
    },
    {
      id: 26,
      name: "Hair Trimmer",
      model: "BF 3003",
      image: BF3003,
      tags: [""],
      size: "",
      list: [
        "Professional Rechargeable Hair Trimmer with 40+ Comb Length Adjustment",
        "Durable Long Lasting Motor",
        "90 Minutes Operating",
      ],
    },
    {
      id: 27,
      name: "Hair Trimmer",
      model: "BF 3004",
      image: BF3004,
      tags: [""],
      size: "",
      list: [
        "13 In Rechargeable Multi Grooming Kit",
        "Durable Long Lasting Motor",
        "120 Minutes Operating",
        "High Precision Sharp Blade",
      ],
    },
    {
      id: 28,
      name: "Hair Trimmer",
      model: "BF 3005",
      image: BF3005,
      tags: [""],
      size: "",
      list: [
        "13 in 1 Rechargeable Grooming Kit With 19 in 1 Precision Setting",
        "100 Minutes Operating",
        "High Precision Sharp Blade",
        "Long Lasting Motor",
      ],
    },
    {
      id: 29,
      name: "Hair Trimmer",
      model: "BF 3006",
      image: BF3006,
      tags: [""],
      size: "",
      list: [
        "Rechargeable Professional Hair Trimmer",
        "Silver pd.alloy Motor For High Rotational Speed",
        "Magic Stainless Steel Blade",
        "Push-Type Switch For Easy Operation",
        "USB Charging",
      ],
    },
    {
      id: 30,
      name: "Hair Trimmer",
      model: "BF 3007",
      image: BF3007,
      tags: [""],
      size: "",
      list: [
        "Professional Rechargeable Heavy Hair Trimmer",
        "LED Display",
        "Charging Indicator 2000mAh",
        "2 Hours Operating",
        "Long Lasting Motor",
      ],
    },
    {
      id: 31,
      name: "Hair Trimmer",
      model: "BF 3008",
      image: BF3008,
      tags: [""],
      size: "",
      list: [
        "Professional Hair Trimmer",
        "Stainless Steel Blade",
        "C-Type Charging",
        "Li-ion Battery",
        "Charging Time 2H & Discharging Time 50 Min",
        "High Power Motor",
        "Push-Type Switch for Easy Operation",
      ],
    },
    {
      id: 32,
      name: "Hair Trimmer",
      model: "BF 3009",
      image: BF3009,
      tags: [""],
      size: "",
      list: [
        "Professional",
        "LED Display",
        "C-Type Interface",
        "Charging Time 3H & Discharging Time 2.5H",
        "Metal Body",
        "Low Noise, Steel Blade, and Zero Cut",
        "Li-ion Battery",
      ],
    },
    {
      id: 33,
      name: "Hair Straightener",
      model: "BF 3050",
      image: BF3050,
      tags: [""],
      size: "",
      list: [
        "Professional",
        "Max Temperature 2000C",
        "Power 30-35W",
        "PTC Heating Element",
        "Ceramic Coating Plate",
        "Rapid Heating",
        "Straight & Curl",
        "LED Charging Indicator",
        "360° Rotating power cord",
      ],
    },
    {
      id: 34,
      name: "LED Flashlight",
      model: "BF 1004",
      image: BF1004,
      tags: [""],
      size: "",
      list: [
        "2 SC Battery Size",
        "Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "3600mAh Battery",
        "1800 Meters Range",
      ],
    },
    {
      id: 35,
      name: "LED Flashlight",
      model: "BF 1006",
      image: BF1006,
      tags: [""],
      size: "",
      list: [
        "2 C Battery Size",
        "Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "3600mAh Battery",
        "2000 Meters Range",
      ],
    },
    {
      id: 36,
      name: "LED Flashlight",
      model: "BF 1007",
      image: BF1007,
      tags: [""],
      size: "",
      list: [
        "3 C Battery Size",
        "Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "3600mAh Battery",
        "2300 Meters Range",
      ],
    },
    {
      id: 37,
      name: "LED Flashlight",
      model: "BF 1008",
      image: BF1008,
      tags: [""],
      size: "",
      list: [
        "2 D Battery Size",
        "Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "4000mAh Battery",
        "2400 Meters Range",
      ],
    },
    {
      id: 38,
      name: "LED Flashlight",
      model: "BF 1004 V2",
      image: BF1004V2,
      tags: [""],
      size: "",
      list: [
        "2 in 1 (2SCx2) Combo Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "3600mAh Battery",
        "2400 Meters Range",
      ],
    },
    {
      id: 39,
      name: "LED Flashlight",
      model: "BF 1005 V3",
      image: BF1005V3,
      tags: [""],
      size: "",
      list: [
        "3 in 1 (3SCx3) Combo Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "6000mAh Battery",
        "2400 Meters Range",
      ],
    },
    {
      id: 40,
      name: "LED Flashlight",
      model: "BF 1005 V4",
      image: BF1005V4,
      tags: [""],
      size: "",
      list: [
        "4 in 1 (3SCx4) Combo Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "6000mAh Battery",
        "2400 Meters Range",
      ],
    },
    {
      id: 41,
      name: "LED Flashlight",
      model: "BF 1001",
      image: BF1001,
      tags: [""],
      size: "",
      list: [
        "Aircraft Aluminum Alloy Body",
        "2500mAh 18650 Rechargeable Battery",
        "Super Bright White Laser LED",
        "Type-C Charging Cable",
        "High-Med-Low-Scrobe",
        "Water Resistance",
        "Shock Resistance",
        "1300M Range",
      ],
    },
    {
      id: 42,
      name: "LED Flashlight",
      model: "BF 1002",
      image: BF1002,
      tags: [""],
      size: "",
      list: [
        "1 AA Battery Size",
        "Rechargeable LED Flashlight",
        "Water Resistant",
        "Shock Resistant",
        "Corrosion Resistant",
        "1800mAh Battery",
        "1000 Meters Range",
      ],
    },
    {
      id: 43,
      name: "LED Flashlight",
      model: "BF 1003",
      image: BF1003,
      tags: [""],
      size: "",
      list: [
        "Aircraft Aluminum Alloy Body",
        "1*26650 Battery",
        "4500mAh Rechargeable Lithium Ion Battery",
        "Super Bright XHP50 LED",
        "Type-C Charging Cable",
        "Water Resistance",
        "Shock Resistance",
        "2000M Range",
      ],
    },
    {
      id: 44,
      name: "Sandwich Maker",
      model: "BF 9007",
      image: BF9007,
      tags: [""],
      size: "",
      list: [
        "Two Slice Sandwich Maker With Fixed Grill Plate",
        "Thermostat Automatically Control",
        "Safety Fuse- For Overheat protection",
        "Cool Touch Handle",
        "Anti-Slip Rubber Feet",
        "Shock-Proof Bakelite Body",
        "Auto Power Off",
        "Dual Neon Indicator",
      ],
    },
    {
      id: 45,
      name: "Air Fryer",
      model: "BF 4501",
      image: BF4501,
      tags: [""],
      size: "",
      list: [
        "Digital Air Fryer With Single Pot 4.3 L",
        "Adjustable Temperature",
        "Non-Stick Coated Frying Pot",
        "Over-Heat Protection",
        "Cool-Touch Handle",
        "Non-Stick Teflon Coating",
        "Automatic Shut Off When Pot Is Taken Out",
        "1-60 Minutes Timer",
      ],
    },
    {
      id: 46,
      name: "Air Fryer",
      model: "BF 4502",
      image: BF4502,
      tags: [""],
      size: "",
      list: [
        "Mechanical Air Fryer With Double Basket 5.5L",
        "Cool Touch Handle",
        "Safety Fuse for Over-Heat Protection",
        "Adjustable temperature range 800°C-2000°C",
        "Automatic Shut Off When Pot Is Taken Out",
        "1-30 Minutes Timer",
        "Non-Stick Teflon Coating",
      ],
    },
    {
      id: 47,
      name: "Air Fryer",
      model: "BF 4503",
      image: BF4503,
      tags: [""],
      size: "",
      list: [
        "Digital Air Fryer With Single Basket 7.0 L",
        "Adjustable Temperature Range 800°C-2000°C",
        "Non-Stick Coated Frying Pot & Rack",
        "Over-Heat Protection",
        "Cool-Touch Handle",
        "Non-Stick Teflon Coating",
        "Automatic Shut Off When Pot Is Taken Out",
        "1-60 Minutes Timer",
      ],
    },
  ];

  const product = products.find((item) => item.id === parseInt(productId));

  let recommendedProducts = products.filter(
    (item) => item.name === product.name && item.id !== parseInt(productId)
  );

  if (recommendedProducts.length < 4) {
    const additionalProducts = products
      .filter(
        (item) =>
          !recommendedProducts.includes(item) && item.id !== parseInt(productId)
      )
      .sort(() => 0.5 - Math.random());

    recommendedProducts = [...recommendedProducts, ...additionalProducts];
  }
  recommendedProducts = recommendedProducts.slice(0, 4);

  return (
    <div className="productinformation">
      <div className="info-section">
        <img src={product.image} alt={product.name} />
        <div className="infoprod">
          <div>
          <h2>{product.name}</h2>
          <p>
            <strong></strong> {product.model}
          </p>
          <p>Features</p>
          <ul>
            {product.list.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          </div>
          <div>
          <a href="https://wa.me/+97474717616" className="info-btn">
            <button>Buy Now</button>
          </a>
          </div>
        </div>
      </div>
      <div className="recommended-products">
        <h3 className="heading">RELATED PRODUCTS</h3>
        <hr />
        <div className="product-grid">
          {recommendedProducts.map((item) => (
            <div key={item.id} className="product-card">
              <div className="rec-img">
                <img src={item.image} alt={item.name} />
              </div>
              <div className="product_title">
                <h4>{item.name}</h4>
                <p> {item.model}</p>
              </div>
              <Link to={`/product-info-page/${item.id}`}>View Details</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Productinfo;
