import React, { useEffect, useState } from 'react';
import "../Compontents/Prodpage.css";
import { Link, useLocation } from 'react-router-dom';
import {  BF7001, BF7002, BF5001, BF5002, BF5003, BF5004, BF5005, BF5006, BF7003, BF7007, BF5501, BF5502, BF5503, BF6001, BF6002, BF6003, BF6006, BF4001, BF4002, BF4003, BF4004, BF2002GS, BF2006GS, BF3001, BF3002, BF3003, BF3004, BF3005, BF3006, BF3007, BF3008, BF3009, BF3050, BF1004, BF1006, BF1007, BF1008, BF1004V2, BF1005V3, BF1005V4, BF1001, BF1002, BF1003, BF9007, BF4501, BF4502, BF4503 } from '../assets';
import Sidebar from './Sidebar';

const Mainprod = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');
  const searchQueryFromURL = queryParams.get('search') || "";

  
  const categoryMap = {
    "home-appliances": ["Electric Kettle","Sandwich Maker","Air Fryer"],
    "lighting": ["LED Flashlight"],
    "personal-care": ["Hair Straightener", "Hair Trimmer"],
    "kitchen-essentials": ["Electric Rice Cooker","Electric Food Chopper","Blender","Gas Stove"],
    "iron-and-laundry": ["Heavy Dry Iron","Steam Iron"]
  };
  const products = [
    { id: 1, name: 'Steam Iron', model: 'BF 7001', image: BF7001, tags: ["Best Seller"], size: "Medium" },
    { id: 2, name: 'Steam Iron', model: 'BF 7002', image: BF7002, tags: ["New"], size: "Small" },
    { id: 3, name: 'Steam Iron', model: 'BF 7003', image: BF7003, tags: ["New"], size: "Small" },
    { id: 4, name: 'Heavy Dry Iron', model: 'BF 7007', image: BF7007, tags: ["Sales"], size: "Large" },
    { id: 5, name: 'Blender', model: 'BF 5001', image: BF5001, tags: [""], size: "" },
    { id: 6, name: 'Blender', model: 'BF 5002', image: BF5002, tags: [""], size: "" },
    { id: 7, name: 'Blender', model: 'BF 5003', image: BF5003, tags: [""], size: "" },
    { id: 8, name: 'Blender', model: 'BF 5004', image: BF5004, tags: [""], size: "" },
    { id: 9, name: 'Blender', model: 'BF 5005', image: BF5005, tags: [""], size: "" },
    { id: 10, name: 'Blender', model: 'BF 5006', image: BF5006, tags: [""], size: "" },
    { id: 11, name: 'Electric Food Chopper', model: 'BF 5501', image: BF5501, tags: [""], size: "" },
    { id: 12, name: 'Electric Food Chopper', model: 'BF 5502', image: BF5502, tags: [""], size: "" },
    { id: 13, name: 'Electric Food Chopper', model: 'BF 5503', image: BF5503, tags: [""], size: "" },
    { id: 14, name: 'Electric Kettle', model: 'BF 6001', image: BF6001, tags: [""], size: "" },
    { id: 15, name: 'Electric Kettle', model: 'BF 6002', image: BF6002, tags: [""], size: "" },
    { id: 16, name: 'Electric Kettle', model: 'BF 6003', image: BF6003, tags: [""], size: "" },
    { id: 17, name: 'Electric Kettle', model: 'BF 6006', image: BF6006, tags: [""], size: "" },
    { id: 18, name: 'Electric Rice Cooker', model: 'BF 4001', image: BF4001, tags: [""], size: "" },
    { id: 19, name: 'Electric Rice Cooker', model: 'BF 4002', image: BF4002, tags: [""], size: "" },
    { id: 20, name: 'Electric Rice Cooker', model: 'BF 4003', image: BF4003, tags: [""], size: "" },
    { id: 21, name: 'Electric Rice Cooker', model: 'BF 4004', image: BF4004, tags: [""], size: "" },
    { id: 22, name: 'Gas Stove', model: 'BF 2002 GS', image: BF2002GS, tags: [""], size: "" },
    { id: 23, name: 'Gas Stove', model: 'BF 2006 GS', image: BF2006GS, tags: [""], size: "" },
    { id: 24, name: 'Hair Trimmer', model: 'BF 3001', image: BF3001, tags: [""], size: "" },
    { id: 25, name: 'Hair Trimmer', model: 'BF 3002', image: BF3002, tags: [""], size: "" },
    { id: 26, name: 'Hair Trimmer', model: 'BF 3003', image: BF3003, tags: [""], size: "" },
    { id: 27, name: 'Hair Trimmer', model: 'BF 3004', image: BF3004, tags: [""], size: "" },
    { id: 28, name: 'Hair Trimmer', model: 'BF 3005', image: BF3005, tags: [""], size: "" },
    { id: 29, name: 'Hair Trimmer', model: 'BF 3006', image: BF3006, tags: [""], size: "" },
    { id: 30, name: 'Hair Trimmer', model: 'BF 3007', image: BF3007, tags: [""], size: "" },
    { id: 31, name: 'Hair Trimmer', model: 'BF 3008', image: BF3008, tags: [""], size: "" },
    { id: 32, name: 'Hair Trimmer', model: 'BF 3009', image: BF3009, tags: [""], size: "" },
    { id: 33, name: 'Hair Straightener', model: 'BF 3050', image: BF3050, tags: [""], size: "" },
    { id: 34, name: 'LED Flashlight', model: 'BF 1004', image: BF1004, tags: [""], size: "" },
    { id: 35, name: 'LED Flashlight', model: 'BF 1006', image: BF1006, tags: [""], size: "" },
    { id: 36, name: 'LED Flashlight', model: 'BF 1007', image: BF1007, tags: [""], size: "" },
    { id: 37, name: 'LED Flashlight', model: 'BF 1008', image: BF1008, tags: [""], size: "" },
    { id: 38, name: 'LED Flashlight', model: 'BF 1004 V2', image: BF1004V2, tags: [""], size: "" },
    { id: 39, name: 'LED Flashlight', model: 'BF 1005 V3', image: BF1005V3, tags: [""], size: "" },
    { id: 40, name: 'LED Flashlight', model: 'BF 1005 V4', image: BF1005V4, tags: [""], size: "" },
    { id: 41, name: 'LED Flashlight', model: 'BF 1001', image: BF1001, tags: [""], size: "" },
    { id: 42, name: 'LED Flashlight', model: 'BF 1002', image: BF1002, tags: [""], size: "" },
    { id: 43, name: 'LED Flashlight', model: 'BF 1003', image: BF1003, tags: [""], size: "" },
    { id: 44, name: 'Sandwich Maker', model: 'BF 9007', image: BF9007, tags: [""], size: "" },
    { id: 45, name: 'Air Fryer', model: 'BF 4501', image: BF4501, tags: [""], size: "" },
    { id: 46, name: 'Air Fryer', model: 'BF 4502', image: BF4502, tags: [""], size: "" },
    { id: 47, name: 'Air Fryer', model: 'BF 4503', image: BF4503, tags: [""], size: "" },
  ];

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [searchQuery, setSearchQuery] = useState(searchQueryFromURL);


  const filterProducts = (type, value) => {
    if (value === "All") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) => {
        if (type === "tag") return product.tags.includes(value);
        if (type === "name"){
          return Array.isArray(value) ? value.includes(product.name) : product.name === value;
        }
        if (type === "size") return product.size === value;
        return false;
      });
      setFilteredProducts(filtered);
    }
  };
  useEffect(() => {
    let filteredProducts = products;
  
    // Filter by category if category exists
    if (category && categoryMap[category]) {
      filteredProducts = filteredProducts.filter(product =>
        categoryMap[category].includes(product.name)
      );
    }
    
    // Further filter by search query if it exists
    if (searchQueryFromURL) {
      filteredProducts = filteredProducts.filter(product =>
        product.name.toLowerCase().includes(searchQueryFromURL.toLowerCase())
      );
    }
    setFilteredProducts(filteredProducts);
  }, [category, searchQueryFromURL]);
  

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(query) || product.model.toLowerCase().includes(query)
    );
    setFilteredProducts(filtered);
  };


  return (
    <div className="product-page">
      <Sidebar filterProducts={filterProducts} productCount={filteredProducts.length} />
      <div className="product-list">
        <div className="breadcrumb">
          <p>Home / Products /<span style={{color : 'black'}}>{category
        ? ` ${category.replace('-', ' ').replace(/\b\w/g, (c) => c.toUpperCase())}`
        : ' All'}</span></p>
        </div>
        <div className="header">
          <input
            type="text"
            placeholder="Search Products..."
            value={searchQuery}
            onChange={handleSearch}
            className="search-bar"
          />
        </div>
        <div className="products">
          {filteredProducts.map((product) => (
            <div className="product-card" key={product.id}>
              <img src={product.image} alt={product.name} loading='lazy'/>
              <p>{product.name} ({product.model})</p>
              <Link to={`/product-info-page/${product.id}`}>Quick View</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mainprod;
