import React from 'react'
import Home from '../Compontents/Home'
import Products from '../Compontents/Products'
import Offers from '../Compontents/Offers'
import Featured from '../Compontents/Featured'
import Category from '../Compontents/Category'
import ContactSection from '../Compontents/ContactSection'
import Footer from '../Compontents/Footer'
import AboutSection from '../Compontents/AboutSection'
import ProductFeatures from '../Compontents/ProductFeatures'


const Homepage = () => {
  return (
    <>
    <Home/>
    <Products/>
    <Offers/>
    <Featured/>
    <ProductFeatures/>
    <Category/>
    <AboutSection/>
    <ContactSection/>
    <Footer/>
    </>
  )
}

export default Homepage