import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import { Hero2, Hero3, Hero4, Hero5, Logo } from "../assets/index.js";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";

const Home = () => {
  const images = [Hero2, Hero3, Hero4, Hero5];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Function to toggle to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to toggle to the previous image
  const previousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/products-page?search=${(searchQuery)}`);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(nextImage, 7000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const nextIndex = (currentIndex + 1) % images.length;
    const nextImage = new Image();
    nextImage.src = images[nextIndex];
  }, [currentIndex]);

  return (
    <div className="home">
      <div className="inside-banner">
        <div className="banner-container">
          <img
            className="background-image"
            src={images[currentIndex]}
            alt="Banner"
          />
        </div>
        <div>
          <div
            className="content2"
            onClick={nextImage}
            style={{ cursor: "pointer" }}
          >
            <BsArrowRightCircle />
          </div>
          <div
            className="content3"
            onClick={previousImage}
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftCircle />
          </div>
        </div>
      </div>
      <div className="homebannerin">
        <Link
          className="view-button"
          to="/products-page?category=home-appliances"
        >
          Explore Now
        </Link>
      </div>
      <a href="/" className="content"></a>
      <div className={`topwindow ${isScrolled ? "scrolled" : ""}`}>
        <div>
          <img src={Logo} alt="Logo" className="Logo-img" />
        </div>
        <div className="hamburger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>
        <nav className={`Menu ${isMenuOpen ? "menu-open" : ""}`}>
          <Link to="/" className="home-nav" onClick={() => setIsMenuOpen(false)}>
            Home
          </Link>
          <Link
            to="/products-page?category=home-appliances"
            className="home-nav"
            onClick={() => setIsMenuOpen(false)}
          >
            Products
          </Link>
          <Link to="/about-page" className="home-nav" onClick={() => setIsMenuOpen(false)}>
            About
          </Link>
          <Link to="/contact-page" className="home-nav" onClick={() => setIsMenuOpen(false)}>
            Contact
          </Link>
        </nav>
        <div>
          <form onSubmit={handleSearch} className="search-form">
            <input
              type="text"
              placeholder="Search Products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
            <button type="submit" className="search-button">
              Search
            </button>
          </form>
        </div>
      </div>
      <a
      href="https://wa.me/+97474717616" // Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      className="chat-button"
    >
      <FaWhatsapp className="whatsapp-icon" />
    </a>
    </div>
  );
};

export default Home;
