import React from 'react';
import { Link } from 'react-router-dom';
import "../Compontents/Category.css";
import { cat1, cat2, cat3, cat4, cat5 } from '../assets';

const Category = () => {
  const categories = [
    { name: "Home Appliances", image: cat1, path: "home-appliances" },
    { name: "Lighting", image: cat2, path: "lighting" },
    { name: "Personal Care", image: cat3, path: "personal-care" },
    { name: "Kitchen Essentials", image: cat4, path: "kitchen-essentials" },
    { name: "Ironing & Laundry", image: cat5, path: "iron-and-laundry"}
  ];

  return (
    <div className="category-section">
      <div className="heading">CATEGORY</div>
      <hr />
      <div className="category-grid">
        {categories.map((category, index) => (
          <div className="category-item" key={index}>
            <Link to={`/products-page?category=${category.path}`} className="category-image">
            <img src={category.image} alt={category.name} /></Link>
            <p className="category-name">{category.name}</p>
            <Link to={`/products-page?category=${category.path}`} className="view-link">View</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
