import React from 'react'
import Navbar from '../Compontents/Navbar'
import Contact from '../Compontents/Contact'
import Footer from '../Compontents/Footer'

const Contactpage = () => {
  return (
    <>
    <Navbar/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default Contactpage