import React from 'react'
import { Aboutbg, Contactbanner } from '../assets'
import "../Compontents/Contact.css"

const Contact = () => {
  return (
    <>
    <div className="about-ban">
      <div className="about-banner">
        <h1>CONTACT US</h1>
        <div className="imgBox">
          <img src={Contactbanner} alt="Banner" />
        </div>
        <div className="cutting2"></div>
      </div>
    </div>
    <div class="contact-section2">
    <h2>We are always ready to help you and answer your questions</h2>
    <div class="contact-details">
      <div class="customer-care">
        <h3>Customer Care</h3>
        <a href="tel:+97477100662">+974 77100662</a>
        <br/>
        <a href="tel:+97430062562">+974 30062562</a>
      </div>
      <div class="location">
        <h3>Our Location</h3>
        <p>Qatar Company</p>
        <p>TEEKEYS INTERNATIONAL TRADING WLL</p>
        <p>Doha, Qatar</p>
      </div>
    </div>
    <div class="email-link">
      <h3>Email</h3>
      <a href="mailto:teekeysinternational@gmail.com">teekeysinternational@gmail.com</a>
      <h3>Link</h3>
      <a href="https://teekeysinternational.com">teekeysinternational.com</a>
    </div>
  </div>
</>
  
  )
}

export default Contact