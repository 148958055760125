import React from 'react'
import Mainprod from '../Compontents/Mainprod'
import Navbar from '../Compontents/Navbar'
import Footer from '../Compontents/Footer'
import ProCat from '../Compontents/ProCat'
const Prodpage = () => {
  return (
    <>
    <Navbar/>
    <ProCat/>
    <Mainprod/>
    <Footer/>
    </>
  )
}

export default Prodpage