import React from 'react'
import Navbar from '../Compontents/Navbar'
import Footer from '../Compontents/Footer'
import Aboutbanner from '../Compontents/About-banner'
import MileStone from '../Compontents/MileStone'

const Aboutpage = () => {
  return (
    <>
    <Navbar/>
    <Aboutbanner/>
    <MileStone/>
    <Footer/>
    </>
  )
}

export default Aboutpage