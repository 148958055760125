import React, { useEffect, useState } from 'react';
import "../Compontents/Prodpage.css";
import { Logo } from '../assets';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import hamburger and close icons

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // Track menu open/close state

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header-navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="navbar-sec">
        <a href="/" className="navbar-logo">
          <img src={Logo} alt="Black & Fighter" />
        </a>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <div className={`Navbar-menu ${menuOpen ? "open" : ""}`}>
          <Link to="/" className="Prodpage-navbar" onClick={toggleMenu}>
            Home
          </Link>
          <Link
            to="/products-page?category=home-appliances"
            className="Prodpage-navbar"
            onClick={toggleMenu}
          >
            Products
          </Link>
          <Link to="/about-page" className="Prodpage-navbar" onClick={toggleMenu}>
            About
          </Link>
          <Link to="/contact-page" className="Prodpage-navbar" onClick={toggleMenu}>
            Contact
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
