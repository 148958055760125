import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "../Compontents/Category.css";
import { cat1, cat2, cat3, cat4, cat5 } from "../assets";

const ProCat = () => {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Retrieve the query parameter from the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");

    if (category) {
      setSelectedCategory(category); // Set to selected category from URL
    } else {
      setSelectedCategory(null);
    }
  }, [location]);

  const categories = [
    { name: "Home Appliances", image: cat1, path: "home-appliances" },
    { name: "Lighting", image: cat2, path: "lighting" },
    { name: "Personal Care", image: cat3, path: "personal-care" },
    { name: "Kitchen Essentials", image: cat4, path: "kitchen-essentials" },
    { name: "Ironing & Laundry", image: cat5, path: "iron-and-laundry" },
  ];

  return (
    <div className="category-grid procat-main mobile-grid">
      {categories.map((category, index) => (
        <div className="category-item mobile-item" key={index}>
          <div className="catimg">
            <Link
              to={`/products-page?category=${category.path}`}
              className={`category-image ${
                selectedCategory === category.path ? "selected" : ""
              }`}
              onClick={() => setSelectedCategory(category.path)}
            >
              <img src={category.image} alt={category.name} />
            </Link>
          </div>
          <div className="catname">
            <p className="category-name">{category.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProCat;
