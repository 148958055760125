import React, { useEffect, useRef, useState } from 'react';
import "../Compontents/MileStone.css";
import { Black } from '../assets';

// Create a separate MilestoneItem component to render individual milestones
const MilestoneItem = ({ image, title, year, description, isLeftAligned }) => {
  return (
    <div className={`time-container ${isLeftAligned ? "left-container" : "right-container"}`}>
      <img src={image} alt={title} />
      <div className="text-box">
        <h2>{title}</h2>
        <small>{year}</small>
        <p>{description}</p>
        <span className={`${isLeftAligned ? "left-container-arrow" : "right-container-arrow"}`}></span>
      </div>
    </div>
  );
};

const MileStone = () => {
  const milestones = [
    {
      image: Black,
      title: "Foundation and Vision",
      year: "2002",
      description:
        "BLACK&FIGHTER® Electronics is established in Saudi Arabia, with a vision to provide top-quality electronics and home appliances that elevate everyday life. The brand focuses on reliability, innovation, and customer satisfaction.",
      isLeftAligned: true,
    },
    {
      image: Black,
      title: "Launch of Rechargeable LED Flashlight",
      year: "2004",
      description:
        "The first product, the Rechargeable LED Flashlight, is introduced, marking the beginning of BLACK&FIGHTER®’s commitment to durable and reliable home essentials.",
      isLeftAligned: false,
    },
    {
      image: Black,
      title: "Introduction of DVD Player",
      year: "2005",
      description:
        "BLACK&FIGHTER® diversifies its offering with the DVD Player, entering the home entertainment space with a reliable and affordable option for consumers.",
      isLeftAligned: true,
    },
    {
      image: Black,
      title: "Launch of Emergency Lantern",
      year: "2006",
      description:
        "To meet the growing need for emergency preparedness, the Emergency Lantern is introduced, providing a dependable light source during power outages and outdoor activities.",
      isLeftAligned: false,
    },
    {
      image: Black,
      title: "Heavy Duty Dry Iron",
      year: "2008",
      description:
        "BLACK&FIGHTER® enhances its home appliance range with the launch of the Heavy Duty Dry Iron, offering superior performance and reliability for everyday ironing needs.",
      isLeftAligned: true,
    },
    {
      image: Black,
      title: "Launch of Gas Stove",
      year: "2010",
      description:
        "BLACK&FIGHTER® introduces the Gas Stove, providing a high-performance cooking solution that caters to the growing demand for modern kitchen appliances.",
      isLeftAligned: false,
    },
    {
      image: Black,
      title: "Expansion into Qatar",
      year: "2011",
      description:
        "BLACK&FIGHTER® expands its operations into Qatar, marking a significant milestone in its growth and establishing a strong presence in the Gulf region.",
      isLeftAligned: true,
    },
    {
      image: Black,
      title: "Introduction of Personal Care and Kitchen Appliances",
      year: "2012",
      description:
        "The brand expands its product portfolio by launching the Hair Trimmer, Electric Rice Cooker, Blender, and Steam Iron, meeting the increasing demand for both personal care and kitchen appliances.",
      isLeftAligned: false,
    },
    {
      image: Black,
      title: " Launch of Electric Kettle and Electric Chopper",
      year: "2014",
      description:
        "BLACK&FIGHTER® introduces the Electric Kettle, a quick and efficient solution for boiling water, and the Electric Chopper, making food preparation faster and more convenient.",
      isLeftAligned: true,
    },
    {
      image: Black,
      title: "Air Fryer Innovation",
      year: "2016",
      description:
        "Responding to the growing trend of healthy eating, BLACK&FIGHTER® launches the Air Fryer, enabling consumers to prepare crispy, delicious food with less oil.",
      isLeftAligned: false,
    },
    {
      image: Black,
      title: "Introduction of Hair Straightener",
      year: "2018",
      description:
        "The Hair Straightener is introduced, offering consumers a premium tool for professional-quality hair styling at home.",
      isLeftAligned: true,
    },
    {
      image: Black,
      title: "Sandwich Maker & Coffee Maker",
      year: "2020",
      description:
        "BLACK&FIGHTER® enhances its kitchen appliance lineup with the Sandwich Maker, followed by the Coffee Maker, offering consumers a convenient way to prepare tasty sandwiches and perfect coffee.",
      isLeftAligned: false,
    },
    {
      image: Black,
      title: "Brand Expansion and Product Innovation",
      year: "2022",
      description:
        "BLACK&FIGHTER® continues its expansion into international markets and introduces new product lines with advanced features, reinforcing its position as a leader in the home appliance and electronics sectors.",
      isLeftAligned: true,
    },
    {
      image: Black,
      title: "20th Anniversary Celebration",
      year: "2023",
      description:
        "BLACK&FIGHTER® celebrates 20 years of success, growth, and innovation. The brand continues to prioritize cutting-edge technology, sustainability, and customer satisfaction.",
      isLeftAligned: false,
    },
    {
      image: Black,
      title: "Commitment to Sustainability and Global Expansion",
      year: "2024",
      description:
        "As part of its ongoing commitment to sustainability, BLACK&FIGHTER® launches a range of energy-efficient and smart home appliances. The brand continues to expand globally, cementing its reputation as a trusted name in electronics and home appliances.",
      isLeftAligned: true,
    },
  ];

  return (
    <div className="timeline">
      {milestones.map((milestone, index) => (
        <MilestoneItem
          key={index}
          image={milestone.image}
          title={milestone.title}
          year={milestone.year}
          description={milestone.description}
          isLeftAligned={milestone.isLeftAligned}
        />
      ))}
    </div>
  );
};

export default MileStone;
