import React, { useState } from "react";
import "../Compontents/About-page.css";
import { Aboutbg } from "../assets";
// import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";

const Aboutbanner = () => {
  // const [activeSection, setActiveSection] = useState(null);

  const sections = [
    {
      title: "OUR EXPERTISE",
      content:
        "Our team consists of highly skilled professionals whose expertise ensures that we uphold the highest standards at every stage, from design and manufacturing to distribution and customer service. Each product is crafted with precision and rigorously tested to deliver consistent performance, providing reliability that our customers can count on, day in and day out.",
    },
    {
      title: "OUR MISSION",
      content:
        "To lead the industry by designing, manufacturing, distributing, and servicing premium electronics and home appliances products worldwide. We are committed to empowering individuals and families to live life to the fullest through superior quality and cutting-edge technology that make every day more convenient, enjoyable, and sustainable.",
    },
    { title: "OUR VISION", content: "To become the world's most trusted electronics and home appliances brand, enhancing lives globally through unparalleled quality, innovative solutions, and exceptional customer experiences."},
    { title: "OUR VALUES", content: "At BLACK&FIGHTER®, we are driven by a strong set of values: Innovation, Teamwork, and Reliability. These principles guide us in delivering products that not only meet but exceed customer expectations, fostering trust and satisfaction in everything we do. Through collaboration and unity, we achieve outstanding results for our customers."},
  ];

  // const toggleSection = (index) => {
  //   setActiveSection(activeSection === index ? null : index);
  // };

  return (
    <div className="about-ban">
      <div className="about-banner">
        <h1>ABOUT US</h1>
        <div className="imgBox">
          <img src={Aboutbg} alt="Banner" />
        </div>
        <div className="cutting2"></div>
      </div>
      <div className="about-content">
        For over 20 years, BLACK&FIGHTER® Electronics has been a beacon of
        excellence in the electronics and home appliances industry across the
        Middle East, with a strong presence in Saudi Arabia and Qatar. With a
        legacy built on quality, reliability, and innovation, we provide a
        diverse range of high-performance products designed to make everyday
        life easier and more enjoyable.
        <div className="collapsible-container">
          {sections.map((section, index) => (
            <div key={index} className="collapsible-section">
              <div
                className="section-header"
              >
                <h3>{section.title}</h3>
                <span>
                </span>
              </div>
                <div className="section-content">{section.content}</div>
            </div>
          ))}
        </div>
        <div className="promise">
            <h3>The BLACK&FIGHTER® Promise</h3>
            <p>Our unique selling point is simple: Quality, Guaranteed, and Long-lasting. When you choose BLACK&FIGHTER®, you're choosing products built to perform, crafted for longevity, and backed by decades of trust, integrity, and excellence.</p>
        </div>
        <div className="Milestone">
            <h3>BLACK&FIGHTER® Electronics Milestones (2002–2024)</h3>
            <p>At BLACK&FIGHTER®, we've built a legacy of excellence over the past two decades. Here's a look at some key milestones that have shaped our journey."
            </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutbanner;
